import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiServiceClass } from '../apiurl';
import { Observable } from 'rxjs';
import { LocationBaseService } from '../location-base.service';
@Injectable({
  providedIn: 'root'
})
export class UsercontrolService extends LocationBaseService {



  public api=new ApiServiceClass();

  constructor(private http: HttpClient){
    super();
  }
  public menuList:string;


  login(User:any):Observable<any>{

    let url=this.baseUrl+this.api.UserLogin;

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    return this.http.post<any>(url,User,{headers:headerDict})
  }

  set_accyear(year){
    localStorage.setItem("acc_year",year.toString());


  }
    

  UserLogout():Observable<any>{

    let url=this.baseUrl+this.api.UserLogout;

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    return this.http.post<any>(url,{headers:headerDict})
  }
  UserPermissionIndex(id:any):Observable<any[]>{
    let url=this.baseUrl+this.api.UserPermissionIndex;

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    return this.http.get<any[]>(url,{params:{id:id}})
  }

  UserMenuPermission(model:any):Observable<any>{

    let url=this.baseUrl+this.api.UserMenuPermission;

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    return this.http.post<any>(url,model,{headers:headerDict})
  }

  forgotPassword(emailId){
    let url=this.baseUrl+this.api.ForgotPassword;
     let params={email:emailId};
      return this.http.get<any>(url,{params:params});
  }
}
