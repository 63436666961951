export class ApiServiceClass {

  SelectOrganizationApi = "api/SelectListManager/SelectOrganization";
  SelectParentOrganisationUnit =
    "api/SelectListManager/SelectParentOrganisationUnit";
  SelectDeviceList = "api/SelectListManager/SelectDeviceList";
  SelectParentOrganisationUnitById =
    "api/SelectListManager/SelectParentOrganisationUnit";
  SelectGender = "api/SelectListManager/SelectGender";
  SelectDesignation = "api/SelectListManager/SelectDesignation";
  SelectDesignationAlternative =
    "api/SelectListManager/SelectDesignationAlternative";
  SelectScholarProfileType = "api/SelectListManager/SelectScholarProfileType";
  SelectDesignationByParentById =
    "api/SelectListManager/SelectDesignationByParent";
  SelectDesignationById = "api/SelectListManager/SelectDesignation";
  SelectLeaveSchedule = "api/SelectListManager/SelectLeaveSchedule";
  SelectLeaveScheduleById = "api/SelectListManager/SelectLeaveScheduleById";
  SelectDutySchedule = "api/SelectListManager/SelectDutySchedule";
  EmployementStatus = "api/SelectListManager/EmployementStatus";
  EmployeeList = "api/SelectListManager/EmployeeList";
  SelectAddionalRole = "api/SelectListManager/SelectAddionalRole";
  //--------------------------------------------------------------------

  EmpInformation = "api/EmployeInfo/EmployeList";
  EmpInformationByGroup = "api/EmployeInfo/EmployeListByGroup";
  EmpInformationCreate = "api/EmployeInfo/Create";
  EmpInformationDetails = "api/EmployeInfo/EmployeDetails";
  Documents = "api/Document/UploadImage";
  EmployeOtherInfoUpdate = "api/EmployeInfo/OtherInformation";
  EmployeeUpdateInfo = "api/EmployeInfo/EmployeeServiceUpdate";
  EmployeeDelete = "api/EmployeInfo/Delete";
  ResetAccount = "api/EmployeInfo/ResetActivate";
  ForgotPassword = "api/EmployeInfo/ForgotPassword";

  //-----------------------------------------------------------------------
  EmpLeaveByEmpId = "api/LeaveApplication/GetEmployeLeave";
  EmpLeaveSummeryById = "api/LeaveApplication/GetLeaveSummery";
  EmpleaveIndex = "api/LeaveApplication/Index";
  EmpleaveCreate = "api/LeaveApplication/Create";
  EmpleaveDelete = "api/LeaveApplication/Delete";
  EmplTotalLeave = "api/LeaveApplication/GetTotalLeave";
  EmplSearchIndex = "api/LeaveApplication/SearchIndex";
  EmpLeaveFormApproval = "api/LeaveApplication/LeaveFormApproval"
  EmpLeaveSummery = "api/LeaveApplication/LeaveSummery"
  ImportData = "api/LeaveApplication/ImportData"
  SaveImportData = "api/LeaveApplication/ImportData"
  //-------------------------------------------------------------------------
  EmpHalfleaveIndex = "api/Halfdayleave/Index";
  EmpHalfleaveCreate = "api/Halfdayleave/Create";
  EmpHalfeaveDelete = "api/Halfdayleave/Delete";
  //---------------------------------------------------------------------------
  EmpAttendanceIndex = "api/EmployeeAttendance/AttendanceIndex";
  EmpFilter = "api/EmployeeAttendance/Filter";
  EmpAttendance = "api/EmployeeAttendance/Attendance";
  EmpEmployeeAnalytics = "api/EmployeeAttendance/EmployeeAnalytics";
  EmployeeAnalyticsByEmp = "api/EmployeeAttendance/EmployeeAnalyticsByEmp";
  EmployeAttendanceSummeryByEmpId = "api/Attendance/GetEmployeAtt";
  EmployeeEnrollmentList = "api/EmployeeAttendance/EnrollmentList";
  UploadEmployeeEnrollment = "api/EmployeeAttendance/UploadEmployeeEnrollment";
  ManualAttPunch = "api/hrms/Get";

  //--------------------------------------------------------------------------------

  UserLogin = "api/UserAccount/Login";
  UserLogout = "api/UserAccount/Logout";
  UserPermissionIndex = "api/UserPermission/Index";
  UserMenuPermission = "api/UserPermission/MenuPermission";

  //--------------------------------------------------------------------------------
  EarlyLeaveIndex = "api/EarlyLeave/Index";
  EarlyLeaveForm = "api/EarlyLeave/EarlyLeaveForm";
  EarlyDeleteApplication = "api/EarlyLeave/DeleteApplication";
  EarlyLeaveFormApprovalGet = "api/EarlyLeave/EarlyLeaveFormApproval";
  EarlyLeaveFormApprovalPost = "api/EarlyLeave/EarlyLeaveFormApproval";
  //--------------------------------------------------------------------------------
  NocApplicationIndex = "api/NocApplication/Index";
  NocApplicationForm = "api/NocApplication/NocApplicationForm";
  NocDeleteApplication = "api/NocApplication/DeleteApplication";
  NocFormApprovalGet = "api/NocApplication/NocFormApproval";
  NocFormApprovalPost = "api/NocApplication/NocFormApproval";
  //--------------------------------------------------------------------------------------

  DesignationIndex = "api/Designation/Index";
  DesignationEdit = "api/Designation/Edit";
  DesignationCreate = "api/Designation/Create";
  DesignationDelete = "api/Designation/Delete";

  //----------------------------------------------------------------------------------------

  DutyScheduleIndex = "api/DutySchedule/Index";
  DutyScheduleEdit = "api/DutySchedule/Edit";
  DutyScheduleCreate = "api/DutySchedule/Create";
  DutyScheduleDelete = "api/DutySchedule/Delete";

  //----------------------------------------------------------------------------------------

  EmployeStatusIndex = "api/EmployeStatus/Index";
  EmployeStatusEdit = "api/EmployeStatus/Edit";
  EmployeStatusCreate = "api/EmployeStatus/Create";
  EmployeStatusDelete = "api/EmployeStatus/Delete";

  //----------------------------------------------------------------------------------------

  HolidayIndex = "api/Holiday/Index";
  HolidayEdit = "api/Holiday/Edit";
  HolidayCreate = "api/Holiday/Create";
  HolidayDelete = "api/Holiday/Delete";
  //----------------------------------------------------------------------------------------

  LeaveScheduleIndex = "api/LeaveSchedule/Index";
  LeaveScheduleEdit = "api/LeaveSchedule/Edit";
  LeaveScheduleCreate = "api/LeaveSchedule/Create";
  LeaveScheduleDelete = "api/LeaveSchedule/Delete";
  LeaveTypeInvidisualUpdate = "api/LeaveSchedule/LeaveTypeInvidisualUpdate";

  //----------------------------------------------------------------------------------------

  OrganizationalUnitIndex = "api/OrganizationalUnit/Index";
  OrganizationalUnitEdit = "api/OrganizationalUnit/Edit";
  OrganizationalUnitCreate = "api/OrganizationalUnit/Create";
  OrganizationalUnitDelete = "api/OrganizationalUnit/Delete";
  //----------------------------------------------------------------------------------------

  OrganizationIndex = "api/Organization/Index";
  OrganizationEdit = "api/Organization/Edit";
  OrganizationCreate = "api/Organization/Create";

  //----------------------------------------------------------------------------------------

  ApprovalManagerGet = "api/ApprovalManager/Get";
  ApprovalManagerGetById = "api/ApprovalManager/Get";
  ApprovalManagerPost = "api/ApprovalManager/Post";
  ApprovalManagerDelete = "api/ApprovalManager/Delete";
  //----------------------------------------------------------------------------------------

  PromotionGet = "api/Promotion";
  PromotionDelete = "api/Promotion/Delete";
  //----------------------------------------------------------------------------------

  //----------------------------------------------------------------------------------------

  AddionalRole = "api/AddionalRole";
  AddionalRoleDelete = "api/AddionalRole/Delete";
  //----------------------------------------------------------------------------------

  EmpDocuments = "api/Document/EmpDocuments";
  DocumentUpload = "api/Document/Upload";
  DownloadDocument = "api/Document/DownloadDocument";
  DeleteDocument = "api/Document/DeleteDocument";

  //----------------------------------------------------------------------------------

  EnrollmentUserDeviceInfo = "api/Device/Index";
  EnrollmentUserToDevice = "api/Device/EnrollmentUser";
  DeviceUserList = "api/Device/WdmsDeviceData";

  //----------------------------------------------------------------------------------

  ImportantDocument = "api/ImportantDocument";
  AdminDashboard = "api/Dasboard";

}
