export class ModelUser{
  public Id :number;

  public  UserId:string;


  public  Type:string;

  public  Password :string;
  public RoleId :string;
  public IsLogined:boolean=true;
}
