import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModelUser } from '../models/ModelUser';
import { UsercontrolService } from '../services/usercontrol.service';
declare const $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {

  constructor(public spinner:NgxSpinnerService ,public userControlService:UsercontrolService,public route:Router) { }
  ngAfterViewInit(): void {

  }
  model=new ModelUser();
  errorMessage="";
  public version: string = "version : 1.0.5";
  forgotPasswordCheck:boolean=false;
  ngOnInit(): void {
    this.model.Type='';

  }

  redirectTo(uri:string){
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.route.navigate([uri]));
 }
  loginUser(){
    this.spinner.show();
    this.userControlService.login(this.model).subscribe((res:any)=>{



      if(res.result==true)
      {
        localStorage.setItem('menuList', JSON.stringify(res.menulist) );
        localStorage.setItem('user', JSON.stringify(res.user) );

        localStorage.setItem('token',res.user.Token);
        let currentYear = new Date().getFullYear();    
        this.userControlService.set_accyear(currentYear);
        if(this.model.Type=='administrator')
        {
         // this.spinner.hide();
        return  window.location.href="/dashboard"
      //  return  this.route.navigate(['/admin']);
        }else
        {
          // this.spinner.hide();
            //this.details.aditionalcss='page-wrapper';
            return  window.location.href="/user/index"
           // return   this.route.navigate(['/admin/empDetails']);
        }
      }else{
        this.model.IsLogined=false;
        this.errorMessage="login authorization is failed";
      }
      this.spinner.hide();
    })
  }

  forgotPassword(value){
    this.spinner.show();

    this.userControlService.forgotPassword(value).subscribe((res)=>{

      this.spinner.hide();
      alert(res.Message);
    })
  }
  imageObject: Array<object> = [{
    image: '../../assets/Images/Logo/hr-bnr-1.jpg',
    thumbImage: '../../assets/Images/Logo/hr-bnr-1.jpg',
    alt: 'alt of image',
    width:'100%',
    title: 'title of image'
  }, {
    image: '../../assets/Images/Logo/hr-bnr-2.jpg',
    thumbImage: '../../assets/Images/Logo/hr-bnr-2.jpg',
    title: 'Image title',
    width:'100%',
    alt: 'Image alt'
  },
  ];
}
