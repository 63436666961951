import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardIndexComponent } from './dashboard-index/dashboard-index.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  // { path: 'login', loadChildren: () => import(`./login/login.module`).then(m => m.LoginModule) },
  // { path: 'sign-up', loadChildren: () => import(`./sign-up/sign-up.module`).then(m => m.SignUpModule) },
 // {path: 'error', loadChildren: () => import(`./errorpages/errorpages.module`).then(m => m.ErrorpagesModule) },
  {path: '', component:HomeComponent},
  {path: 'dashboard', component:DashboardComponent},
  {path:'admin',loadChildren:()=>import(`./admin/app-admin-module.module`).then(m=>m.AppAdminModuleModule)},
  {path:'user',loadChildren:()=>import(`./user/user.module`).then(m=>m.UserModule)},
  {path:'error',loadChildren:()=>import(`./errorpages/errorpages.module`).then(m=>m.ErrorpagesModule)}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
