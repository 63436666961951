import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DetailsComponent } from '../admin/all-emplyoeinfo/details/details.component';
import { ModelUser } from '../models/ModelUser';
import { UsercontrolService } from '../services/usercontrol.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements AfterViewInit {

  constructor(public spinner:NgxSpinnerService,public userControlService:UsercontrolService,public route:Router) { }
  ngAfterViewInit(): void {

  }
  model=new ModelUser();
  ngOnInit(): void {
    this.model.Type='';
  }
  public details:DetailsComponent;
  redirectTo(uri:string){
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.route.navigate([uri]));
 }
  loginUser(){

    this.userControlService.login(this.model).subscribe((res:any)=>{

      if(res.result==true)
      {
        localStorage.setItem('menuList', JSON.stringify(res.menulist) );
        localStorage.setItem('user', JSON.stringify(res.user) );
    
        
      

        if(this.model.Type=='administrator')
        {

          return  window.location.href="/admin"
        }else
        {
            //this.details.aditionalcss='page-wrapper';
            this.route.navigate(['/user/index']);
        }
      }

    })
  }


}
