import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { UsercontrolService } from '../services/usercontrol.service';
import { HeaderService } from "./header.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  jsonData: any = {
    notification: [],
    message: [],
  };
  notifications: any;
  messagesData: any;
  user:any={};
  imagesrc:any;
  accademic_year:any[]=[];
  current_year:number=0;
  constructor(private headerService: HeaderService,
    private router: Router,public userServiceList:UsercontrolService) {}

  logout(){
    this.userServiceList.UserLogout().subscribe((res)=>{
      if(res==true){
        localStorage.clear();
        return  window.location.href="/";
      }
    })
  }

  setacc_year(year)
  {
    this.userServiceList.set_accyear(year);
    this.current_year=JSON.parse(localStorage.getItem("acc_year"));
    
  }
  init_acc_year()
  {
    let currentYear = new Date().getFullYear();    
    let earliestYear = 2021;     
    
    
    this.current_year=JSON.parse(localStorage.getItem("acc_year"));
    
    while (currentYear >= earliestYear)
     {      
      // let dateOption = document.createElement('option');          
      // dateOption.text = currentYear;      
      // dateOption.value = currentYear;        
      this.accademic_year.push(currentYear);      
      currentYear -= 1;    
    }
  }
  ngOnInit() {

    let user=JSON.parse(localStorage.getItem("user"));


    this.init_acc_year()

    this.user.Type=user.Type;
    this.user.UserName=user.UserName;
    this.imagesrc="/EmployeeProfile/"+user.Id+".PNG?"+Math.floor(Math.random()*100+1);

   
    // this.getDatas("notification");
    // this.getDatas("message");

    // this.notifications = [
    //   {
    //     message: "Patient appointment booking",
    //     author: "John Doe",
    //     function: "added new task",
    //     time: "4 mins ago",
    //   }
    // ];

    // this.messagesData = [
    //   {
    //     message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    //     author: "Mike Litorus",
    //     time: "4 mins ago",
    //   },
    //   {
    //     message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    //     author: "Mike Litorus",
    //     time: "1 hour ago",
    //   },
    //   {
    //     message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    //     author: "Mike Litorus",
    //     time: "4 mins ago",
    //   },
    //   {
    //     message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    //     author: "Mike Litorus",
    //     time: "1 hour ago",
    //   },
    //   {
    //     message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    //     author: "Mike Litorus",
    //     time: "4 mins ago",
    //   },
    //   {
    //     message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    //     author: "Mike Litorus",
    //     time: "1 hour ago",
    //   },
    // ];
  }

  getDatas(section) {
    this.headerService.getDataFromJson(section).subscribe((data) => {
      this.jsonData[section] = data;
    });
  }

  clearData(section) {
    this.jsonData[section] = [];
  }
  onSubmit() {
    this.router.navigate(["/pages/search"]);
  }
}
