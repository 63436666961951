import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceClass } from '../apiurl';
import { LocationBaseService } from '../location-base.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends LocationBaseService {



  public api=new ApiServiceClass();

  constructor(private http: HttpClient){
    super();
  }
  index():Observable<any>{
    let url=this.baseUrl+this.api.AdminDashboard+"/Index";

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    return this.http.get<any>(url)
  }
 
}
