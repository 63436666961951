import { Component, OnInit } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";


@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  urlComplete = {
    mainUrl: "",
    subUrl: "",
    childUrl: "",
  };

  sidebarMenus = {
    default: true,
    chat: false,
    settings: false,
  };

  members = {};
  groups = {};
  menuList:any[];
  constructor(
    private router: Router,

  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        //alert(event.url);

        //       const url = event.url.split("/");
         this.urlComplete.mainUrl =event.url;
        // this.urlComplete.subUrl = url[2];

      }
    });

    this.menuList=JSON.parse(localStorage.getItem("menuList"));


  }

  ngOnInit():void{

  }
  setActive(member) {

  }
}
