import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { UsercontrolService } from '../services/usercontrol.service';

@Component({
  selector: 'app-body-container',
  templateUrl: './body-container.component.html',
  styleUrls: ['./body-container.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BodyContainerComponent implements AfterViewInit {

 progressbar=0;
  loading  = false;
  menuList:any[];
 public user:any;
  /**
   *
   */
  constructor(public userServiceList:UsercontrolService) {


  }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {



  }

}
