import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { DashboardService } from '../services/dashboard.service';

declare const $: any;
declare const Morris: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterContentInit {


  public projects = [];
  public clients = [];
  public invoices = [];
  public payments = [];
  public att_graph: any[] =[];

  // Initialized to specific date (09.10.2018).
  public model: any = { date: { year: 2018, month: 10, day: 9 } };
  public dash: any = { 'total_employee': 0, 'total_present': 0, 'total_al': 0, 'total_absent': 0 };

  constructor(private router: Router, public service: DashboardService) {

  }

  ngAfterContentInit() {



  }

  get_dasboard() {
    this.service.index().subscribe((res: any) => 
    {
        this.dash = res.dashboard,
        this.model = res;
        this.att_graph=res.dashboard.att_graph
        Morris.Bar(
          {
          element: 'bar-charts',
          data: this.att_graph,
          xkey: 'MonthDate',
          ykeys: ['A', 'P','W','L','AL'],
          labels: ['On Present', ' Absent','Weekend','Late','Aproval Leave'],
          lineColors: ['#f43b48', '#453a94','green','blue','black'],
          lineWidth: '3px',
          barColors: ['#f43b48', '#453a94','green','blue','black'],
          resize: true,
          redraw: true
        });
    
    })
  }
  ngOnInit() {

   
    var pro_heights = $(".panel-eqHeight-clients").map(function () {
      return $(this).height();
    }).get(),
      pro_maxHeight = Math.max.apply(null, pro_heights);
    $(".panel-eqHeight-projects").height(pro_maxHeight);
    $(".panel-eqHeight-clients").height(pro_maxHeight);

    var pay_heights = $(".panel-eqHeight-invoices").map(function () {
      return $(this).height();
    }).get(),
      pay_maxHeight = Math.max.apply(null, pay_heights);
    $(".panel-eqHeight-payments").height(pay_maxHeight);
    $(".panel-eqHeight-invoices").height(pay_maxHeight);

    // Area Chart

    // Morris.Area(
    //   {
    //   element: 'area-charts',
    //   data: [
    //     { y: '2006', a: 100, b: 90 },
    //     { y: '2007', a: 75,  b: 65 },
    //     { y: '2008', a: 50,  b: 40 },
    //     { y: '2009', a: 75,  b: 65 },
    //     { y: '2010', a: 50,  b: 40 },
    //     { y: '2011', a: 75,  b: 65 },
    //     { y: '2012', a: 100, b: 90 }
    //   ],
    //   xkey: 'y',
    //   ykeys: ['a', 'b'],
    //   labels: ['Total Invoice', 'Pending Invoice'],
    //   lineColors: ['#f43b48','#453a94'],
    //   lineWidth: '3px',
    //   resize: true,
    //   redraw: true
    //   });
    this.get_dasboard();

    

   

    // Bar Chart

    // Line Chart

    Morris.Line({
      element: 'line-charts',
      data: [
        { y: '2006', a: 50, b: 90 },
        { y: '2007', a: 75, b: 65 },
        { y: '2008', a: 50, b: 40 },
        { y: '2009', a: 75, b: 65 },
        { y: '2010', a: 50, b: 40 },
        { y: '2011', a: 75, b: 65 },
        { y: '2012', a: 100, b: 50 }
      ],
      xkey: 'y',
      ykeys: ['a', 'b'],
      labels: ['Total Sales', 'Total Revenue'],
      lineColors: ['#f43b48', '#453a94'],
      lineWidth: '3px',
      resize: true,
      redraw: true
    });

    // Donut Chart

    Morris.Donut({
      element: 'pie-charts',
      colors: [
        '#f43b48',
        '#453a94',
        '#f8878e',
        '#aaa3db'
      ],
      data: [
        { label: "Employees", value: 30 },
        { label: "Clients", value: 15 },
        { label: "Projects", value: 45 },
        { label: "Tasks", value: 10 }
      ],
      resize: true,
      redraw: true
    });

  }

}
